<template>
  <section class="home row justify-content-center margin-atas">
    <div class="col-md-8">
      <h1 class="text-center mb-5">Pameran Online</h1>
      <ul
        class="row justify-content-center nav nav-pills mb-3"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="pills-home-tab"
            data-toggle="pill"
            href="#pills-home"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            <span class="fa fa-camera"></span> Foto
          </a>
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="pills-profile-tab"
            data-toggle="pill"
            href="#pills-profile"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
            ><span class="fa fa-video"></span> Video</a
          >
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div class="row">
            <div
              class="mb-3 area col-md-6"
              v-for="item in images"
              :key="item.no"
            >
              <img
                id="myImg"
                @click="showDetImage(item.image)"
                :src="require('../../assets/pameran/' + item.image)"
              />
              <div class="middle">
                <div class="text"><strong>Klik untuk memperbesar</strong></div>
              </div>
              <!-- The Modal -->
              <div id="myModal" class="modal">
                <span class="close">&times;</span>
                <img class="modal-content" id="img01" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
        >
          <div class="row">
            <div class="col-md-6" v-for="(item, index) in videos" :key="index">
              <video controls controlsList="nodownload">
                <source :src="require('../../assets/pameran/' + item.video)" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          no: 10,
          image: "10.jpg",
        },
        {
          no: 11,
          image: "11.jpg",
        },
        {
          no: 12,
          image: "12.jpg",
        },
        {
          no: 13,
          image: "13.jpg",
        },
        {
          no: 1,
          image: "1.jpg",
        },
        {
          no: 2,
          image: "2.jpg",
        },

        {
          no: 4,
          image: "4.jpg",
        },
        {
          no: 5,
          image: "5.jpg",
        },
        {
          no: 6,
          image: "6.jpg",
        },
        {
          no: 7,
          image: "7.jpg",
        },
        {
          no: 3,
          image: "3.jpg",
        },
        {
          no: 8,
          image: "8.jpg",
        },
        {
          no: 9,
          image: "9.jpg",
        },
        {
          no: 14,
          image: "14.jpg",
        },
        {
          no: 15,
          image: "15.jpg",
        },
        {
          no: 17,
          image: "17.jpeg",
        },
        {
          no: 18,
          image: "18.jpeg",
        },
        {
          no: 19,
          image: "19.jpeg",
        },
        {
          no: 20,
          image: "20.jpeg",
        },
        {
          no: 21,
          image: "21.jpeg",
        },
        {
          no: 22,
          image: "22.jpeg",
        },
        {
          no: 23,
          image: "23.jpeg",
        },
        {
          no: 24,
          image: "24.jpeg",
        },
        {
          no: 25,
          image: "25.jpeg",
        },
        {
          no: 26,
          image: "26.jpeg",
        },
        {
          no: 27,
          image: "27.jpeg",
        },
        {
          no: 16,
          image: "16.jpg",
        },
      ],
      videos: [
        { video: "28.mp4" },
        { video: "29.mp4" },
        { video: "30.mp4" },
        { video: "31.mp4" },
        { video: "32.mp4" },
      ],
    };
  },
  created() {
      document.title = "Pameran Online RSUD GAMBIRAN 2021";
  },
  methods: {
    showDetImage(gambar) {
      var modal = document.querySelector("#myModal");
      var img = document.getElementById("myImg");
      var modalImg = document.getElementById("img01");
      modal.style.display = "block";
      modalImg.src = require("../../assets/pameran/" + gambar);
      var span = document.getElementsByClassName("close")[0];
      span.onclick = function () {
        modal.style.display = "none";
      };
    },
  },
};
</script>

<style>
.margin-atas {
  margin-top: -100px;
}

.show {
  display: block;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.area:hover .middle {
  opacity: 1;
}

#myImg:hover {
  opacity: 0.3;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
</style>
